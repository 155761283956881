import { Injectable } from '@angular/core';
import { LpMeta } from '../meta/meta';
import { UserService } from '../services/user.service';
import { HttpProvider } from './http.provider';
import { ZoomMetaData } from 'app/models/zoom-metada';
import { HttpError } from 'app/models/http-error';
import { ApplicationItem } from 'app/models/application-item';
import { ApplicationItemDetail } from 'app/models/application-item-detail';
import { MetadataDetails } from 'app/models/metadata-details';
import { Util } from 'app/statics/utils';
import { BreadcrumbItem } from 'app/models/breadcrumb-item';
import { UserInterface, Preference } from 'app/models/user.interface';
import { LpLogin } from 'app/meta/login';
import { Eval } from 'app/models/eval';
import { WidgetInterface } from 'app/models/widget';
import { Dashboard, Widget } from 'app/models/dashboard';
import { tap } from 'rxjs/operators';
import { ConfigService } from 'app/services/config.service';
import { UserHistory } from 'app/models/user-history';
import { Observable } from 'rxjs';

const CUSTOM_CODE_FUNCTIONS: string = 'custom-code-functions';
const CUSTOM_CODE_SCRIPTS: string = 'custom-code-scripts';
export const LOGIN: string = 'login';
export const MAINTENANCE: string = 'maintenance';
export const REFRESH_TOKEN: string = `${LOGIN}/refresh-token`;
export const LOGOUT: string = 'logout';
const ZOOMS: String = 'zooms';
export const APPLICATION_ITEMS: string = 'application-items';
const APPLICATION_ITEM_DETAILS: String = 'application-item-details';
const DETAILS: String = 'details'
const MODULES: String = 'modules';
export const USERS_PREFERENCE: String = 'user-preferences';
export const JSON_SERVER_SCHEDULER_CONFIG_KEY: string = 'scheduler-configs';
export const BREADCRUMB: string = 'breadcrumb-items'
export const APPLICATION_ITEM_SCREENID: String = 'applicationItemScreenNumber';
export const APPLICATION_ITEM_VERB: String = 'applicationItemVerb';
export const DASHBOARD: String = 'dashboards';
export const CUSTOM_APPLICATION_ITEM_DETAIL: String = 'custom-application-item-details';
export const USER_HISTORY: string = 'user-history';
export const WIDGETS: string = 'widgets';

@Injectable({
  providedIn: 'root'
})
/**
 * Le provider repositoryProvider permet de faire des appels vers le JSON server.
 * ce dernier contient toutes les meta-data utilisées dans LP3K comme par exemple
 * les entêtes des colonnes des zomms pricipaux, les infos à afficher dans le
 * détail d'un zomm (zoom-details), etc...
 */
export class RepositoryProvider {

  protected repositoryMapCache: Map<String, any> = new Map;

  constructor(
    protected http: HttpProvider,
    public userService: UserService,
    private configService: ConfigService) {
  }

  public init(): void {
    this.http.addHeader('Content-Type', 'application/json');
    this.http.addHeader('X-VEGA-token', '');
    this.http.addHeader('Accept-Language', this.configService.get('requestOptionsHeaders').acceptLanguage);
    this.http.addHeader('X-VEGA-version', this.configService.get('requestOptionsHeaders').xVEGAversion);
    this.http.addHeader('X-VEGA-app', this.configService.get('requestOptionsHeaders').xVEGAapp);
    this.http.addHeader('X-VEGA-serial', this.configService.get('requestOptionsHeaders').xVEGAserial);
    if (
      this.configService.get('requestOptionsHeaders').xVEGAcontext !== undefined 
      && this.configService.get('requestOptionsHeaders').xVEGAcontext !== null
      && this.configService.get('requestOptionsHeaders').xVEGAcontext !== ""  ) {
        this.http.addHeader('X-VEGA-context', this.configService.get('requestOptionsHeaders').xVEGAcontext);
    }
    this.http.addHeader('X-GYZMO-Api-Key', '');

    // set the current logged user token in the request headers (token will be needed by lp3kRepository)
    this.setXvegaTokenInHeaders();
  }

  public login(username: string, password: string): Promise<UserInterface> {
    if ( this.configService.get('hashPassword') === true) {
      const hashedPassword: string = this.userService.hashPassword(password);
      password = String(hashedPassword);
    }
    const params: LpLogin = new LpLogin(username, password);
    return new Promise<any>((resolve, reject) => {
      this.post(LOGIN, params).then((data) => {
        resolve(data.body);
      }).catch((e) => {
        reject(e);
      });
    });
  }

  public refreshToken(): Observable<any> {
    let user: UserInterface = this.userService.getCurrentUser();
    if (user.accessToken && user.refreshToken) {
      return this.http.getClient().post(`${this.configService.get('repositoryServiceUrl')}${REFRESH_TOKEN}/` 
            , { "accessToken": user.accessToken, "refreshToken": user.refreshToken })
          .pipe(
            tap((user: UserInterface) => {
        if (user) {
          this.userService.refreshUser(user);
        }
      })
      )
    }
  }

  public getCustomCodeFunctions(): Promise<any> {
    this.setXvegaTokenInHeaders();
    return this.get(CUSTOM_CODE_FUNCTIONS, null, null, true);
  }

  public getCustomCodeFunction(functionName: string): Promise<string> {
    this.setXvegaTokenInHeaders();
    return this.get(`${CUSTOM_CODE_FUNCTIONS}/${functionName}`);
  }

  public async getCustomCodeScripts(idEcran: string, sub: string, event: string, field: string, search: string): Promise<any> {
    this.setXvegaTokenInHeaders();
    let fieldEncoded: string = encodeURIComponent(`${field}`);
    return await this.get(`${CUSTOM_CODE_SCRIPTS}/${idEcran}/${sub}/${fieldEncoded}/${event}`, search, false);
  }

  public getCustomCodeScriptsByEcran(idEcran: string): Promise<Array<Eval>> {
    this.setXvegaTokenInHeaders();
    return this.get(`${CUSTOM_CODE_SCRIPTS}/${idEcran}`, null, null, true);
  }

  public async saveCustomCodeByEcran(ecrId: string, data: string): Promise<void> {
    let result: Promise<void> = await this.put(CUSTOM_CODE_SCRIPTS, ecrId, data);
    this.repositoryMapCache.clear();
    return result;
  }

  public async saveCustomCodefunction(functionName: string, data: any): Promise<void> {
      if(!Util.isNullOrUndefined(data.name) && data.name !== ''){
        if(await this.functionExists(data)){
          let result: Promise<void> = await this.put(CUSTOM_CODE_FUNCTIONS, functionName, data);
          this.repositoryMapCache.clear();
          return result;
        } else {
          throw new Error('general.modalService.functionNameExists');  
        }
      } else {
        throw new Error('general.modalService.functionNameNeeded');
      }
  }

  public async createCustomCodefunction(data: any): Promise<any> {
    let temp: any = await this.post(CUSTOM_CODE_FUNCTIONS, data);
    this.repositoryMapCache.clear();
    return temp.body;
  }

  public async saveMenu(data: any): Promise<void> {
    await this.post('menus', data);
  }

  public async deleteMenu(data: LpMeta): Promise<void> {
    await this.delete('menus', data);
  }

  /** La fonction GetZoomMetaData(), permet de récupéré les infos selon l'id du zoom du composant
   * (colonnes du zoom, détails d'un zoom field, etc...)
  */
  public getZoomMetadata(id: String): Promise<ZoomMetaData> {
    this.setXvegaTokenInHeaders();
    let baseUrl: String = `${this.configService.get('repositoryServiceUrl')}${ZOOMS}/${id}`;
    if (this.existInCache(baseUrl)) {
      return new Promise<ZoomMetaData>((resolve) => {
        resolve(this.repositoryMapCache.get(baseUrl));
      });
    } else {
      return new Promise<ZoomMetaData>((resolve, reject) => {
        this.http.httpGet(baseUrl.toString()).then((data: ZoomMetaData) => {
          this.setDataInCache(data, baseUrl);
          resolve(data);
        });
      });
    }
  }

  public getApplicationItem(verb: String): Promise<ApplicationItem> {
    this.setXvegaTokenInHeaders();
    let baseUrl: String = `${this.configService.get('repositoryServiceUrl')}${APPLICATION_ITEMS}/${verb}`;
    if (this.existInCache(baseUrl)) {
      return new Promise<ApplicationItem>((resolve) => {
        resolve(this.repositoryMapCache.get(baseUrl));
      });
    } else {
      return new Promise<ApplicationItem>((resolve) => {
        this.http.httpGet(`${this.configService.get('repositoryServiceUrl')}${APPLICATION_ITEMS}/${verb}`).then((data: ApplicationItem) => {
          this.setDataInCache(data, baseUrl);
          resolve(data);
        });
      });
    }
  }

  public getApplicationItems(): Promise<Array<ApplicationItem>> {
    this.setXvegaTokenInHeaders();
    let baseUrl: String = `${this.configService.get('repositoryServiceUrl')}${APPLICATION_ITEMS}/`;
    if (this.existInCache(baseUrl)) {
      return new Promise<Array<ApplicationItem>>((resolve) => {
        resolve(this.repositoryMapCache.get(baseUrl));
      });
    } else {
      return new Promise<Array<ApplicationItem>>((resolve) => {
        this.http.httpGet(`${this.configService.get('repositoryServiceUrl')}${APPLICATION_ITEMS}/`).then((data: Array<ApplicationItem>) => {
          this.setDataInCache(data, baseUrl);
          resolve(data);
        });
      });
    }
  }

  public getApplicationItemFromJsonEditor(verb: String): Promise<ApplicationItem> {
    this.setXvegaTokenInHeaders();
    this.clearCache(verb);
    let baseUrl: String = `${this.configService.get('repositoryServiceUrl')}${APPLICATION_ITEMS}/${verb}`;
    return new Promise<ApplicationItem>((resolve) => {
      this.http.httpGet(`${this.configService.get('repositoryServiceUrl')}${APPLICATION_ITEMS}/${verb}`).then((data: ApplicationItem) => {
        this.setDataInCache(data, baseUrl);
        resolve(data);
      });
    });
  }

  public async saveApplicationItem(verb: string, data: any): Promise<void> {
    let result: Promise<void> = await this.put(APPLICATION_ITEMS, data.id, data);
    this.repositoryMapCache.clear();
    return result;
  }

  public getBreadcrumbItem(verb: String): Promise<BreadcrumbItem> {
    this.setXvegaTokenInHeaders();
    let baseUrl: String = `${this.configService.get('repositoryServiceUrl')}${BREADCRUMB}/${verb}`;
    if (this.existInCache(baseUrl)) {
      return new Promise<BreadcrumbItem>((resolve) => {
        resolve(this.repositoryMapCache.get(baseUrl));
      });
    } else {
      return new Promise<BreadcrumbItem>((resolve) => {
        this.http.httpGet(`${this.configService.get('repositoryServiceUrl')}${BREADCRUMB}/${verb}`).then((data: BreadcrumbItem) => {
          this.setDataInCache(data, baseUrl);
          resolve(data);
        });
      });
    }
  }

  public getschedulerConfig(): Promise<any> {
    this.setXvegaTokenInHeaders();
    return this.get(JSON_SERVER_SCHEDULER_CONFIG_KEY, null, false);
  }

  public putPostSchedulerConfig(data: any): Promise<any> {
    return this.post(JSON_SERVER_SCHEDULER_CONFIG_KEY, data);
  }

  public deleteCustomConfig(id: number): Promise<void> {
    return this.deleteById(JSON_SERVER_SCHEDULER_CONFIG_KEY, id.toString());
  }

  public getApplicationItemDetails(key: string, nocach?: boolean): Promise<ApplicationItemDetail> {
    return this.get(`${APPLICATION_ITEM_DETAILS.toString()}/${key}`, null, null, nocach);
  }

  /** La fonction getMetadataDetails(), permet de récupérer les infos à afficher dans un lp-field-details.
  */
  public async getMetadataDetails(verb: String, ecrId: string): Promise<MetadataDetails> {
    let baseUrl: String;
    let metadataDetails: MetadataDetails;

    baseUrl = `${DETAILS}/${verb}/${ecrId}`
    try {
      if (this.existInCache(baseUrl)) {
        metadataDetails = this.repositoryMapCache.get(baseUrl);
      } else {
        metadataDetails = await this.get(baseUrl.toString());
        if (Util.isNullOrUndefined(metadataDetails.path)) {
          // TODO voir si on peut utiliser l'id comme cela après le passage des id en numérique dans Json server.
          metadataDetails.path = metadataDetails.id;
        }
        this.setDataInCache(metadataDetails, baseUrl);
      }
    } catch (err) {
      let e: HttpError = err.error;
      throw e;
    }
    return new Promise<MetadataDetails>((resolve) => {
      resolve(metadataDetails);
    });
  }

  public get(verb: string, searched?: String, restPattern?: boolean, noCache?: boolean): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      try {
        this.setXvegaTokenInHeaders();
        let search: String = '';
        if (searched) {
          search = `${searched}`;
        }
        let baseUrl: String = '';
        if (search != '') {
          if (Util.isNullOrUndefined(restPattern) || restPattern) {
            baseUrl = `${this.configService.get('repositoryServiceUrl')}${verb}/${search}`;
          } else {
            baseUrl = `${this.configService.get('repositoryServiceUrl')}${verb}?${search}`;
          }           
        } else {
          baseUrl = `${this.configService.get('repositoryServiceUrl')}${verb}`;
        }
        if ((Util.isNullOrUndefined(noCache) || !noCache) && this.existInCache(baseUrl)) {
          let temp: any = this.repositoryMapCache.get(baseUrl)
          resolve(temp);
        } else {
          this.http.httpGet(baseUrl.toString()).then((r: any) => {
            if ((Util.isNullOrUndefined(noCache) || !noCache)) {
              this.setDataInCache(r, baseUrl);
            }
            resolve(r);
          });
        }
      } catch (err) {
        console.log(err);
        reject(err);
      }
    });
  }

  public async put(verb: string, id: String, data: any): Promise<any> {
    this.setXvegaTokenInHeaders();
    let url = `${this.configService.get('repositoryServiceUrl')}${verb}/${id}`;
    if (Util.isNullOrUndefined(id)) {
      url = `${this.configService.get('repositoryServiceUrl')}${verb}`
    }
    return await this.http.httpPut(url, data);
  }

  public async putChangeLocation(verb: string, data: any): Promise<any> {
    this.setXvegaTokenInHeaders();
    return new Promise<any>((resolve, reject) => {
        this.http.httpPut(`${this.configService.get('repositoryServiceUrl')}${verb}`, data).then((result: any) => {
          resolve(result);
        }).catch((err) => {
          reject(err);
        });
    });
  }

  public async updateUserPreferences(data: Preference): Promise<Preference> {
    this.setXvegaTokenInHeaders();
    return new Promise<Preference>((resolve) => {
      this.http.httpPut(`${this.configService.get('repositoryServiceUrl')}${USERS_PREFERENCE}`, data).then((result: Preference) => {
        resolve(result);
      });
    });
  }

  public async post(verb: string, data: any): Promise<any> {
    this.setXvegaTokenInHeaders();
    return await this.http.httpPost(`${this.configService.get('repositoryServiceUrl')}${verb}`, data);
  }

  public async logout(): Promise<void> {
    this.setXvegaTokenInHeaders();
    this.clearCache();
    return this.http.httpDelete(`${this.configService.get('repositoryServiceUrl')}${LOGOUT}`).then(() => {
      Promise.resolve();
    })
    .catch(() => {
      Promise.resolve();
    });
  }

  public async delete(verb: string, data: any): Promise<any> {
    let url = '';
    if ( Util.isNullOrUndefined(data) || ( !Util.isNullOrUndefined(data) &&  Util.isNullOrUndefined(data.id))) {
      url = `${this.configService.get('repositoryServiceUrl')}${verb}`
    } else {
      url = `${this.configService.get('repositoryServiceUrl')}${verb}/${data.id}`;
    }
    this.setXvegaTokenInHeaders();
    return new Promise<void>((resolve) => {
        this.http.httpDelete(url).then(() => {
          resolve();
        });
    });
  }

  public deleteById(verb: string, id: string): Promise<void> {
    this.setXvegaTokenInHeaders();
    return new Promise<void>((resolve) => {
        this.http.httpDelete(`${this.configService.get('repositoryServiceUrl')}${verb}/${id}`).then(() => {
          resolve();
        });
    });
  }

  /** La fonction getPreferences(), permet de récupérer les préférences d'un utilisateur.
   * @param : userID : String
   * @return : Promise<any>
  */
  public getPreferences(): Promise<Preference> {
    this.setXvegaTokenInHeaders();
    let baseUrl: String = USERS_PREFERENCE.toString();
    if (this.existInCache(baseUrl)) {
      return new Promise<Preference>((resolve) => {
        resolve(this.repositoryMapCache.get(baseUrl));
      });
    } else {
      return new Promise<Preference>((resolve) => {
        this.get(USERS_PREFERENCE.toString(), null, null, true).then((data: Preference) => {
          this.setDataInCache(data, baseUrl);
          resolve(data);
        });
      });
    }
  }

  public getModules(): Promise<any> {
    this.setXvegaTokenInHeaders();
    return this.get(MODULES.toString());
  }

  public clearCache(key?: String): void {
    if (!key) {
      this.repositoryMapCache.clear();
    } else {
      this.repositoryMapCache.delete(key);
    }
  }

  public async getWidgetConfig(id: string): Promise<WidgetInterface> {
    return await this.get('widgets/' + id);
  }

  public async getDashboardConfiguration(id: string): Promise<Dashboard> {
    return await this.get(`${DASHBOARD}/` + id);
  }

  public async getMyDashboardLevel(): Promise<Dashboard> {
    return await this.get(`${DASHBOARD}/me`);
  }

  public async saveDetailConfig(details: ApplicationItemDetail): Promise<void> {
    await this.post(`${CUSTOM_APPLICATION_ITEM_DETAIL}`, details)
  }

  public async deleteCustomDetail(origineId: string): Promise<void> {
    await this.deleteById(`${CUSTOM_APPLICATION_ITEM_DETAIL}`, origineId);
  }

  public async postHistory(historyObjet: UserHistory): Promise<void> {
    await this.post(`${USER_HISTORY}`, historyObjet);
  }

  public async getHistory(userId: string): Promise<UserHistory[]> {
    return await this.get(USER_HISTORY, `userId=${userId}`, false, true);
  }

  public async deleteHistory(userId): Promise<void> {
    return await this.deleteById(USER_HISTORY, userId);
  }

  public async getAllWidgets(): Promise<Widget[]> {
    return await this.get(WIDGETS, null, false, true);
  }

  protected setXvegaTokenInHeaders(): void {
    const currentUser: UserInterface = this.userService.getCurrentUser();
    if (!Util.isNullOrUndefined(currentUser)) {
      if (!Util.isNullOrUndefined(currentUser.token)) {
        this.http.addHeader('X-VEGA-token', currentUser.token);
      } else if (!Util.isNullOrUndefined(currentUser.accessToken)) {
        this.http.addHeader('X-VEGA-token', currentUser.accessToken);
      }
    }
  }

  private async functionExists(data: any): Promise<boolean> {
    let functions: any = await this.getCustomCodeFunctions()
    for(let f of functions){
      if(f.name === data.name){
        return true;
      }
    }
    return false;
  }

  private existInCache(path: String): boolean {
    if (!Util.isNullOrUndefined(path) && path.indexOf('scheduler-configs') === -1) {
      return this.repositoryMapCache.has(path);
    }
    return false;
  }

  private setDataInCache(data: any, path: String): void {
    this.repositoryMapCache.set(path, data);
  }
}
