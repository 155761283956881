import { Component, OnInit, Inject } from '@angular/core';
import { LpModalComponent } from '../lp-modal.component';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LpFullCompany } from 'app/meta/fullCompany';
import { ConfigService } from 'app/services/config.service';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { FunctionnalProvider } from 'app/providers/functionnal.provider';
import { LpFunctionnal } from 'app/meta/functionnal';

const SHORT_TERM_CONTRACT_SCREEN_ID: string = '11';

@Component({
  selector: 'lp-modal-functionnal',
  templateUrl: './lp-modal-functionnal.component.html'
})
export class LpModalFunctionnalComponent extends LpModalComponent implements OnInit {
   
  public dataForm: LpFullCompany = new LpFullCompany();
  protected arrayClassesElements: Map<String, String> = new Map<String, String>();
  public itemSearched: string;
  public functionnalSettings: LpFunctionnal = new LpFunctionnal();

  constructor(
    public dialogRef: MatDialogRef<LpModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private configService: ConfigService,
    private functionnalProvider: FunctionnalProvider
  ) {
    super(dialogRef, data);
  }

  public async ngOnInit(): Promise<void> {
    this.intArrayElements();
    await this.initialize();
  }

  public onTabChanged($event: MatTabChangeEvent): void{
    this.itemSearched = null;
  }

  private async initialize(): Promise<void> {
    this.functionnalSettings = await this.functionnalProvider.getFunctionnalByScreen('11');
  }

  private intArrayElements(): void {
    this.arrayClassesElements.set('defaultLabel', this.configService.get('constanteClasses').DEFAULT_LABEL);
    this.arrayClassesElements.set('defaultInput', this.configService.get('constanteClasses').DEFAULT_INPUT);
    this.arrayClassesElements.set('defaultPostcode', this.configService.get('constanteClasses').DEFAULT_POSTE_CODE);
  }

  public async save(): Promise<void> {
    await this.functionnalProvider.putFunctionnalByScreen(this.functionnalSettings, SHORT_TERM_CONTRACT_SCREEN_ID);
  }

}
