import { Component, Inject, OnInit } from '@angular/core';
import * as _ from 'underscore'
import { LpModalComponent } from '../lp-modal.component';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormStackService } from 'app/services/form-stack.service';
import { LpVisualSettings } from 'app/meta/visual-settings';
import { VisualSettingsProvider } from 'app/providers/visual-settings.provider';
import { UserService } from 'app/services/user.service';
import { ConfigService } from 'app/services/config.service';

@Component({
  selector: 'lp-modal-visual-settings',
  templateUrl: './lp-modal-visual-settings.component.html'
})
export class LpModalVisualSettingsComponent extends LpModalComponent implements OnInit {
  public visualSettings: LpVisualSettings;
  private profilAllowedToShowVisualTechnicalMenu: string = '';

  constructor(
    public dialogRef: MatDialogRef<LpModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public formstackService: FormStackService,
    private visualSettingsProvider: VisualSettingsProvider,
    private userService: UserService,
    private configService: ConfigService
  ) {
    super(dialogRef, data);
  }
  public ngOnInit(): void {
    this.initVisualTechnicalMenuRights()
  }

  public async save(): Promise<void> {
    await this.visualSettingsProvider.putVisualSettingsByAgencyId(this.formstackService.VisualSettings, '*');
    await this.userService.setVisualSettings(this.formstackService.VisualSettings);
  }

  public userHasRightsToShowTechnicalMenu(): boolean {
    let userHasRights: boolean = false;
    if ( 
        this.userService && 
        this.userService.getCurrentUser() && 
        this.userService.getCurrentUser().profile &&
        this.userService.getCurrentUser().profile.id &&
        this.userService.getCurrentUser().profile.id === this.profilAllowedToShowVisualTechnicalMenu
      ) {
        userHasRights = true;
      }
    return userHasRights;
  }

  private initVisualTechnicalMenuRights(): void {
    this.profilAllowedToShowVisualTechnicalMenu = this.configService.get('profilAllowedToShowTechnicalMenu');
  }


}
