import { Component, ElementRef, HostListener } from '@angular/core';
import { UserHistory } from 'app/models/user-history';
import { DatetimeService } from 'app/services/datetime.service';
import { RouterService } from 'app/services/router.service';
import { UserHistoryService } from 'app/services/user-history.service';

@Component({
  selector: 'lp-user-history',
  templateUrl: './lp-user-history.component.html'
})
export class LpUserHistoryComponent { 
  // TODO : variables à placer dans le service dédié à l'historique pour pouvori utiliser dans toutes l'application    
  
  public numberItemShowed: number = 5; 
  public listShowed:UserHistory[] = [];

  constructor(
    public datetimeService: DatetimeService,
    public routerService: RouterService,
    private _elementRef: ElementRef,
    public userHistoryService: UserHistoryService) { 
  }

  @HostListener('document:click', ['$event.target'])
  public onClick(targetElement: any): void {
    if (!this._elementRef.nativeElement.contains(targetElement)) {
      this.closeList();
    }
  }

  public navigate(list: any) {    
    this.closeList();
    this.routerService.navigate(list.verb, list.id);
  }

  public toggleHistoryList() {
    if (!this.userHistoryService.showHistoryList) {
      this.getlist();
      this.userHistoryService.showHistoryList = true;
    } else {
      this.userHistoryService.showHistoryList = false;
    }
  }

  public async getlist(all: Boolean = false): Promise<void> {
    this.userHistoryService.showButtonMore = false;
    let list: UserHistory[] = await this.userHistoryService.getlist();
    if(list.length > 0 ) {
      list = list.reverse();
      if(!all && list.length > this.numberItemShowed) {
        list = list.slice(0, this.numberItemShowed);
        this.userHistoryService.showButtonMore = true;
      }
    }
    this.listShowed = list;
  }

  public async clearAllHistory(): Promise<void> {
    await this.userHistoryService.clearAllHistory()
    await this.getlist()
  }

  public closeList() : void {
    this.userHistoryService.showHistoryList = false;
    this.userHistoryService.showButtonMore = false;
  }
}
