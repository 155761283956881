<lp-ui-accordion>
    <lp-ui-accordion-group heading="{{formStackService.currentApplicationItemDetail.editForm.title}}" [open]=true key="{{id}}">
        <form id="formTechnicalSpecifications" #formMyComponent="ngForm" (ngSubmit)="submit()" ngNativeValidate class="row">
            
            <ng-container  *ngFor="let f of formStackService.currentApplicationItemDetail.editForm.fields; let i = index">
                <div [ngClass]="getClasses(i, f)">
                    <lp-fields [f]="f" [data]="data" [detailId]="detailId" namePrefix="{{formStackService.currentApplicationItemDetail.key}}." translationPrefix="{{formStackService.currentVerb}}."  [isDetail]="true"></lp-fields>

                </div>
            </ng-container>
            <div class="text-end">
                <button *ngIf="!formStackService.isDataReadOnly()" class="valid-detailForm"><i class="vega-diskette"></i></button>
            </div>
        </form>
    </lp-ui-accordion-group>

    <lp-ui-accordion-group heading="Debugger formulaire" *ngIf="debugg">
        <div>
          <pre>{{ data | json }}</pre>
        </div>
      </lp-ui-accordion-group>
</lp-ui-accordion>