<div class="modal-header">
  <h4 class="modal-title pull-left">{{'preferences.functionnalTitle' | translate}}</h4>

  <div class="pull-right">
    <lp-button-cancel (action)="close()"></lp-button-cancel>
  </div>
</div>
<div class="modal-body">
  <div class="card-box">
    <form #form="ngForm" ngNativeValidate>
      <div class="container-fluid">
        <lp-ui-accordion class="row" [menu]=true>
          <lp-ui-accordion-group heading="{{'preferences.functionnalValues' | translate}}" [open]=true key="general" >
            <div *ngFor="let field of functionnalSettings.fields; let j = index" class="row">
              <div *ngIf="field.fieldType === 1" class="col-6">
                <lp-label-field text="{{field.wording}}" for="{{field.wording}}"></lp-label-field>
                <lp-text-field id="{{field.wording}}" className="textField" class="{{arrayClassesElements.get('defaultInput')}} ps-0" name="{{field.wording}}" cssClass="form-control" [ngModelOptions]="{standalone: true}" [(ngModel)]="field.value" [ignoreChange]="true"></lp-text-field>
              </div>
              <div *ngIf="field.fieldType === 2" class="col-6">
                <lp-label-field text="{{field.wording}}" for="{{field.wording}}"></lp-label-field>
                <lp-boolean-switch-box [ignoreChange]="true" [readonly]="false" name="{{field.wording}}" switchName="{{field.wording}}" id="{{field.wording}}" [(ngModel)]="field.value"></lp-boolean-switch-box>
              </div>
              <!--<div *ngIf="field.field" class="col-6">
                <lp-label-field text="Champs" for="champs_{{field.wording}}"></lp-label-field>
                <lp-text-field id="champs_{{field.wording}}" className="textField" class="{{arrayClassesElements.get('defaultInput')}} ps-0" name="champs_{{field.wording}}" cssClass="form-control" [ngModelOptions]="{standalone: true}" [(ngModel)]="field.field" [ignoreChange]="true"></lp-text-field>
              </div>-->
            </div>
            <div class="col-12 mb-0">
              <button class="transparent-btn pull-right" (click)="save()">
                <i class="vega-diskette"></i>
              </button>
            </div>
          </lp-ui-accordion-group>
        </lp-ui-accordion>
      </div>
    </form>
  </div>
</div>