<a *ngIf="!reportSpoolerService.waitingList || reportSpoolerService.waitingList.length  === 0" class="cursor-pointer">
    <i class="vega-print"></i>
</a>

<a *ngIf="reportSpoolerService.waitingList && reportSpoolerService.waitingList.length  > 0" 
    class="cursor-pointer mat-badge-bottom" 
    matBadge="{{reportSpoolerService.waitingList.length}}"
    (click)="reportSpoolerService.showSpoolerList=!reportSpoolerService.showSpoolerList">
  <i class="vega-print"></i>
</a>

<div class="menu-overlay" [class.d-none]="!reportSpoolerService.showSpoolerList" (click)="closeList()"></div>

<div *ngIf="reportSpoolerService.waitingList && reportSpoolerService.waitingList.length  > 0"  class="text-left list-spooler" [class.d-none]="!reportSpoolerService.showSpoolerList">

  <div>
    <div class="list pt-3">
    <div *ngFor="let spooler of list; let i = index" class="item p-2 mb-2 status-{{spooler.status.id}}">
      <p class="m-0">
        <b>{{spooler.name?.wording}} ({{spooler.linkedObject}})</b>
      </p>
      <span *ngIf="spooler && spooler.status && spooler.status.id && spooler.status.id === '90'" class="btn btn-sm rounded-circle pull-right rounded-circle  bg-dark text-white" aria-label="Close" (click)="download(spooler)" title="{{'reportSpooler.download' | translate}}">
        <i class="icon fa fa-download"></i>
      </span>
      <span *ngIf="spooler && spooler.status && spooler.status.id && spooler.status.id === '90'" class="btn btn-sm rounded-circle pull-right rounded-circle  bg-dark text-white me-2" aria-label="Close" (click)="preview(spooler)" title="{{'reportSpooler.preview' | translate}}">
        <i class="icon fa fa-eye"></i>
      </span>
      <span *ngIf="isCancelable(spooler)" class="btn-danger btn btn-sm rounded-circle pull-right rounded-circle  bg-danger text-white" aria-label="Close" (click)="cancelReport(spooler)" title="{{'reportSpooler.cancel' | translate}}">
        <i class="fa fa-times"></i>
      </span>
      <p class="m-0">
        {{dateTimeService.dateFormatFR(spooler.askedDate)}} -- {{spooler.status.wording}}
      </p>
      
      <div *ngIf="loader == spooler.id" class="loader">
        <lp-ui-loader-mini message="reportSpooler.cancelInProgress"></lp-ui-loader-mini>   
      </div>
    </div>
    </div>
  </div>
  <a class="ms-2 btn btn-danger btn-close-list" aria-label="Close" (click)="closeList();">
    <i class="fa fa-times"></i>
  </a>
  <div class="historyHeader">
    <div class="historyHeaderbutton text-center cursor-pointer" (click)="toggleList()">
      <span class="btn btn-sm btn-primary mb-2" [class.d-none]="list.length <= 5">{{'userHistory.showLess' | translate}}</span>
      <span class="btn btn-sm btn-primary mb-2" [class.d-none]="list.length > 5">{{'userHistory.showAll' | translate}}</span>
    </div>
  </div>
</div>

