import { Injectable } from '@angular/core';
import { UiSyncService } from './ui-sync.service';
import { FormatService } from './format.service';
import { RepositoryProvider } from '../providers/repository.provider';
import { Eval } from 'app/models/eval';
import { FormMetadataProvider } from '../providers/form-metadata.provider';
import { Util } from 'app/statics/utils';
import * as Moment from 'moment';
import { UserService } from './user.service';
import { DatetimeService } from './datetime.service';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'underscore';
import { AlocproProvider } from 'app/providers/alocpro.provider';
import { ModalService } from './modal.service';
import { FormStackService } from './form-stack.service';
import { MemoProvider } from 'app/providers/memo.provider';
import { LpMeta } from 'app/meta/meta';
import { ChangeService } from './change.service';
import { FunctionnalService } from './functionnal.service';

export const FOCUS_IN_EVENT: string = 'onFocusin';
export const FOCUS_OUT_CHANGE_EVENT: string = 'onFocusoutChanged'
export const FOCUS_OUT_EVENT: string = 'onFocusout';
export const CHANGE_EVENT: string = 'onChange';
export const BEFORE_DELETE_EVENT: string = 'onBeforeDelete';
export const BEFORE_GET_EVENT: string = 'onBeforeGet';
export const AFTER_GET_EVENT: string = 'onAfterGet';
export const INIT_NEW_EVENT: string = 'onInitNew';
export const BEFORE_INSERT_EVENT: string = 'onBeforeInsert';
export const BEFORE_SAVE_EVENT: string = 'onBeforeSave';
export const BEFORE_UPDATE_EVENT: string = 'onBeforeUpdate';
export const ON_INIT: string = 'onInit';
export const MAIN: string = 'main';

@Injectable({
  providedIn: 'root'
})
export class EvalService {
  public mapTemp: Map<String, any>= new Map<String, any>();
  public scripts: Array<any> = new Array<any>;

  constructor(
    private uiSyncService: UiSyncService, 
    private repositoryProvider: RepositoryProvider, 
    public modalService: ModalService,
    public userService: UserService, 
    public dateTime: DatetimeService, 
    public translate: TranslateService, 
    public formatService: FormatService,
    public formMetadataProvider: FormMetadataProvider, 
    public alocproProvider: AlocproProvider, 
    public formStackService: FormStackService, 
    public memoProvider: MemoProvider, 
    public changeService: ChangeService,
    public functionnalService: FunctionnalService
  ) {
    try {
      this.repositoryProvider.getCustomCodeFunctions().then((functions: Array<any>) => {
        _.each(functions, async (e) => {
          if (!Util.isNullOrUndefined(e.function) && (e.function.indexOf('return ') !== -1)) {
            this.eval( 'this[\'' + e['name'] + '\']  =' + e['function'] , {}, null);
          }
        })
      });
    } catch (e) {
      console.error(e);
    }
  }


  //async function (rowIndex, line) {let PricingMethod = {id: null, wording: null,includedDays: number,includedKilometers: number,category: any = new FtMeta(),isActive: boolean,amount: Amount = new Amount(),pricingTime: PricingTime = new PricingTime()}; let pricing = {id:string,wording:string,pricingMethods: Array<PricingMethod>,specificPricingMethods?: Array<SpecificPricingMethod>};pricing.id = 'NATIONAL';pricing.wording = this.formStackService.CurrentApplicationItemDetailData[rowIndex-1]['wording'];pricing.pricingMethods = [];pricing.pricingMethods.push({});pricing.pricingMethods[0] = this.formStackService.CurrentApplicationItemDetailData[rowIndex-1];pricing.specificPricingMethods = [];pricing.specificPricingMethods.push({});pricing.specificPricingMethods[0].id = String(this.formStackService.CurrentApplicationItemDetailData[rowIndex-1].id);pricing.specificPricingMethods[0].wording = this.formStackService.CurrentApplicationItemDetailData[rowIndex-1]['wording'];pricing.specificPricingMethods[0].category = line['category'];pricing.specificPricingMethods[0].amount = line['amount'];pricing.specificPricingMethods[0].pricingTime = line['pricingTime'];pricing.specificPricingMethods[0].isActive = line['isActive'];console.log(pricing);return pricing; }

  /**
   * fonction qui execute la contrainte
   */
  public async eval(value: String , data: any, fields: any, params?: any, previousValue?: any, mainData?: any, title?: string): Promise<any> {
    // @ts-ignore // to avoid 'unused' warning
    const moment: any = Moment;
    try {
      if (!Util.isNullOrUndefined(data) && value) {
        // tslint:disable-next-line: no-eval
        let param = data;
        param;
        await this.evalInContext(
          '(async () => {' + value.toString() + '})()', this, value, data, fields, params, previousValue, mainData, title, param);
        //await eval('(async () => {' + value.toString() + '})()');
        this.uiSyncService.loadObject();
      }
      return;
    } catch (e) {
      console.error(e);
    }

  }

  public async getCustomCodeScripts(idEcran, sub, event, field): Promise<any> {
    const scripts = await this.repositoryProvider.getCustomCodeScripts(idEcran, sub, event, field, '_sort=order&_order=asc');
    this.scripts = scripts;
    return this.scripts;
  }

  public evalForCellFunction(scripts: string, lineData: LpMeta, indexRow: number, numberValue: number): void {
    try {
      this.eval(scripts, numberValue, null, null, null, lineData);
    } catch (e) {
      console.error(e);
    }
  }

  public async evalForFocusOutAction(scripts: string, param: any): Promise<void> {
    try {
      await this.eval(scripts, param, param, null, null, null);
      //await eval('(async () => {' + scripts.toString() + '})()');
      this.uiSyncService.loadObject();
      return;
    } catch (e) {
      throw e;
    }  
  }

  public async evalInContext(js: string, context: any, value: String , data: any, fields: any, params?: any, previousValue?: any, mainData?: any, title?: string, param?: any): Promise<any> {
    return await async function() { return await eval(js); }.call(context);
  }

  public async summaryEval(title: string, value: String , data: any, currentData?: any): Promise<string> {
    try {
      if (!Util.isNullOrUndefined(value)) {
        // tslint:disable-next-line: no-eval
        //return async function() { return await eval('(async () => {' + value.toString() + '})()'); }.call(this);
        return await this.evalInContext(
          '(async () => {' + value.toString() + '})()', this, value, data, null, null, null, null, title);
        //return eval('(async () => {' + value.toString() + '})()');
      }
      return;
    } catch (e) {
      console.error(e);
    }
  }


  public async executeContrainteForScheduler(constrainte: string, data: string): Promise<any> {
    try {
      var result = new Function('data', constrainte);
      return result(data)
    } catch (e) {
      console.error(e);
    }
  }

  public async evalWithReturnStatement(value: String , data: any): Promise<boolean> {
    try {
      let isDisplayed: boolean = false;
      if (!Util.isNullOrUndefined(value)) {
        // tslint:disable-next-line: no-eval
        isDisplayed = await this.evalInContext(
          '(async () => {' + value.toString() + '})()', this, value, data, null, null, null, null, null);
         //isDisplayed =  await eval('(async () => {' + value.toString() + '})()');
      }

      return isDisplayed;
    } catch (e) {
      console.error(e);
    }
  }

  public evalSync(value: String , data: any, property?: string, parentData?: any): any {
    try {
      let stringReturned: string = '';
      if (!Util.isNullOrUndefined(value) && !Util.isNullOrUndefined(data)) {
        stringReturned = eval('( () => {' + value.toString() + '})()');
      }
      return stringReturned;
    } catch (e) {
      console.error(e);
    }
  }

  /**
   * fonction qui va chercher sur le json-server via le repository une contrainte
   */
  public async executeContrainte(event: string, sub: string, idEcran: string, field: string, context?: any,
  fieldValue?: any, previousValue?: any, mainData?: any): Promise<void> {
    try {
      return new Promise<void>((resolve) => {
        this.repositoryProvider.getCustomCodeScripts(idEcran, sub, event, field, '_sort=order&_order=asc').then((scripts: Array<Eval>) => {
          scripts.forEach(async (s: Eval) => {
            await this.eval(s.scripts, context, fieldValue, field, previousValue, mainData);
          });
          if (scripts.length > 0) {
            this.uiSyncService.updateZoom(context, field);
          }
          resolve();
        });
      })
    } catch (e) {
      console.error(e);
    }
  }


  /**
   * fonction qui emit un structure écouter par value fiel pour appliquer des propriété sur le dom (lecture seuk, valuer par défaut...)
   
  private handleFieldsEvals(fields: any, dom: any, isVerbAlreadyExists: boolean): void {
    try {
        Object.keys(fields).forEach((field: string) => {
          fields[field].name = field;
          dom.set(field, fields[field]);
        });
        let formMeta: FormMetadataValues = {'field': fields, 'fromPlanning': false
        , 'isVerbAlreadyExists': isVerbAlreadyExists, 'subApi': false};
        this.uiSyncService.emitFormMetadataLoaded(formMeta);
    } catch (e) {
      console.error(e);
    }
  }*/
}
