import { LpMeta } from './meta';

export class LpFunctionnal extends LpMeta {
    public screen: number;
    public fields: Array<LpField>;
}

export class LpField {
    public id: number;
    public wording: string;
    public field: string;
    public fieldType: number;
    public value: string | number;
}



