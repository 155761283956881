import { Component, ElementRef, HostListener, OnInit } from '@angular/core';
import { DatetimeService } from 'app/services/datetime.service';
import { ReportSpoolerService } from 'app/services/report-spooler.service';
import { LpSpoolerEditionStatus } from "app/meta/spooler-edition-status";
import { ReportsProvider } from 'app/providers/reports.provider';
import { Util } from 'app/statics/utils';
//import { Util } from 'app/statics/utils';
/*import { ModalService } from 'app/services/modal.service';
import { LpModalPromiseSpoolerPreviewComponent } from '../lp-modal/lp-modal-spooler-preview/lp-modal-spooler-preview.component';*/
import { ReportService } from 'app/services/report.service';
import { DownloadService } from 'app/services/download.service';
import { UiSyncService } from 'app/services/ui-sync.service';
import { TranslateService } from '@ngx-translate/core';
import { LpModalPromiseSpoolerPreviewComponent } from '../lp-modal/lp-modal-spooler-preview/lp-modal-spooler-preview.component';
import { ModalService } from 'app/services/modal.service';
import { isNullOrUndefined } from '@swimlane/ngx-datatable';

const STATUS_FOR_DOWNLOAD: string = '90';
const FORMAT_DOWNLOAD: string = 'application/pdf';

@Component({
  selector: 'lp-report-spooler',
  templateUrl: './lp-report-spooler.component.html'
})
export class LpReportSpoolerComponent implements OnInit{

  public loader: String;
  public srcPdf: Uint8Array;
  public title: String;
  public list: Array<LpSpoolerEditionStatus> = [];

  constructor(
    public reportSpoolerService : ReportSpoolerService,
    public dateTimeService: DatetimeService,
    private reportProvider: ReportsProvider,
    private modalService: ModalService,
    private reportService: ReportService,
    protected uiSyncService: UiSyncService,
    protected translate: TranslateService,
    private _elementRef: ElementRef,
    private downloadService: DownloadService) { }

    @HostListener('document:click', ['$event.target'])
    public onClick(targetElement: any): void {
      if (!this._elementRef.nativeElement.contains(targetElement)) {
        this.closeList();
      }
      if(this.list.length === 0 && !isNullOrUndefined(this.reportSpoolerService.waitingList)){
        this.list = this.reportSpoolerService.waitingList.slice(0, 5);
      }
    }

  public ngOnInit(): void{
    this.reportSpoolerService.runApplicationCheck();

  }

  public isCancelable(report: LpSpoolerEditionStatus) {    
    return report.status.id !== '02' && report.status.id <= '02';
  }

  public cancelReport(report: LpSpoolerEditionStatus): void {
    this.loader = report.id;
    this.reportProvider.delete(report.id)
    .then( async () => {
      this.loader = null;
      this.reportSpoolerService.runApplicationCheck();
    }).catch((error) => {
      this.loader = null;
    });
  }

  public async download(spooler: LpSpoolerEditionStatus): Promise<void> {
    try {
      await this.downloadSpooler(spooler);
      await this.downloadService.download(this.srcPdf, FORMAT_DOWNLOAD, String(spooler.id));
    } catch (error) {
      throw error;
    }
  }

  public async preview(spooler: LpSpoolerEditionStatus): Promise<void> {
    this.uiSyncService.loader(true, true, this.translate.instant('general.modalService.previewPreparation'));
    try {
      await this.downloadSpooler(spooler);
      await this.modalService.modalPromise(LpModalPromiseSpoolerPreviewComponent, {
        height : '85vh',
        width : '80vw',
        backdropClass: 'alertBackdropClass',
      });
      //window.open(URL.createObjectURL(new Blob ([this.srcPdf], {type: 'application/pdf'   })));
      this.uiSyncService.loader(false);
    } catch (error) {
      this.uiSyncService.loader(false);
      throw error;
    }
  }

  public toggleList(): void {
    this.reportSpoolerService.showSpoolerList = false;
    if(this.list.length > 5){
      this.list = this.list = this.reportSpoolerService.waitingList.slice(0, 5);
    } else {
      this.list = this.reportSpoolerService.waitingList;
    }
    this.reportSpoolerService.showSpoolerList = true;
    
  }

  public async clearList(): Promise<void> {
    await this.reportSpoolerService.clearList();
    this.list = [];
  }


  private async checkStatusSpooler(spooler: LpSpoolerEditionStatus): Promise<boolean> {
    try {
      return (!Util.isNullOrUndefined(spooler) && !Util.isNullOrUndefined(spooler.status) && !Util.isNullOrUndefined(spooler.status.id) && 
              spooler.status.id === STATUS_FOR_DOWNLOAD);
    } catch (error) {
      throw error;
    }
  }

  private async downloadSpooler(spooler: LpSpoolerEditionStatus): Promise<void> {
    try {
      let canBeDownload: boolean = await this.checkStatusSpooler(spooler);
      if (canBeDownload && canBeDownload === true) {
        const report: ArrayBuffer = await this.reportSpoolerService.download(String(spooler.id));
        let pdfBinaryReport: Uint8Array = new Uint8Array(report);
        this.reportService.srcPdfForSpooler = pdfBinaryReport;
        this.srcPdf = pdfBinaryReport;
        this.title = spooler.id;
      }
    } catch (error) {
      throw error;
    }
  }

  public closeList() : void {
    this.reportSpoolerService.showSpoolerList = false;
  }


}
