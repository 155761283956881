<div class="modal-header">
  <h4 class="modal-title pull-left">{{'preferences.customization' | translate}}</h4>

  <div class="pull-right">
    <lp-button-cancel (action)="close()"></lp-button-cancel>
  </div>
</div>
<div class="modal-body">

  <mat-tab-group mat-align-tabs="start" (selectedTabChange)="onTabChanged($event);">

    <mat-tab label="{{ 'Fonctionnel' | translate}}">
      <lp-modal-functionnal></lp-modal-functionnal>
    </mat-tab>

    <mat-tab label="{{ 'Visuel' | translate}}">
      <lp-modal-visual-settings></lp-modal-visual-settings>
    </mat-tab>

    <mat-tab *ngIf="userHasRightsToShowTechnicalMenu()" label="{{ 'Technique' | translate}}">
      <lp-modal-customization></lp-modal-customization>
    </mat-tab>

    <mat-tab label="{{ 'Page d accueil' | translate}}">
      <lp-modal-dashboard></lp-modal-dashboard>
    </mat-tab>
    
  </mat-tab-group>

</div>