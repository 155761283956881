import { Injectable } from '@angular/core';
import { FunctionnalProvider } from 'app/providers/functionnal.provider';
import * as _ from 'lodash';

@Injectable({
  providedIn: 'root',
})
export class FunctionnalService {
  constructor(
    private functionnalProvider: FunctionnalProvider
  ) {
  }

  public get(screen: string, field: string): any {
    return this.functionnalProvider.getFunctionnalByScreenAndField(screen, field);
  }


}
