import { Injectable } from '@angular/core';
import { RepositoryProvider } from './repository.provider'
import { ModalService } from 'app/services/modal.service';
import { TranslateService } from '@ngx-translate/core';
import { LpFunctionnal } from 'app/meta/functionnal';

const FUNCTIONNAL: string = 'functionnal';

@Injectable({
    providedIn: 'root'
})

export class FunctionnalProvider {

    constructor(
        private repositoryProvider: RepositoryProvider,
        private translate: TranslateService,
        private modalService: ModalService
        ) {};
    

        public async getFunctionnalByScreen(id: string): Promise<LpFunctionnal> {
            return await this.repositoryProvider.get(`${FUNCTIONNAL}/${id}`, null, null, false);
        }

        public async getFunctionnalByScreenAndField(id: string, field: string): Promise<LpFunctionnal> {
            return await this.repositoryProvider.get(`${FUNCTIONNAL}/${id}/${field}`, null, null, false);
        }

        public async putFunctionnalByScreen(functionnal: LpFunctionnal, id: string): Promise<void> {
            return await this.repositoryProvider.put(`${FUNCTIONNAL}/${id}`, null, functionnal).then(() => {
                this.modalService.success(this.translate.instant('general.modalService.successVisualSettings'),
                  this.translate.instant('general.modalService.success'));
              }).catch((error) => {
                this.modalService.error(this.translate.instant('general.modalService.errorVisualSettings'),
                  this.translate.instant('general.modalService.erreur'));
              });;
        }

        public async postFunctionnalByScreen(functionnal: LpFunctionnal): Promise<LpFunctionnal> {
            return await this.repositoryProvider.post(`${FUNCTIONNAL}`, functionnal);
        }

        public async deleteFunctionnalByScreen(functionnal: LpFunctionnal, id: string): Promise<LpFunctionnal> {
            return await this.repositoryProvider.delete(`${FUNCTIONNAL}/${id}`, functionnal);
        }

}
