<a class="cursor-pointer" (click)="toggleHistoryList();">
    <i class="vega-tasks"></i>
</a>
<div class="menu-overlay" [class.d-none]="!userHistoryService.showHistoryList" (click)="closeList()"></div>
<div class="text-left list-history" [class.d-none]="!userHistoryService.showHistoryList">  
  <div>
    <div class="list pt-3">
      <div *ngFor="let history of listShowed; let i = index" class="item p-0 mb-2">
        <div class="event {{history.event}} me-2 pt-3 pb-3" title="{{'userHistory.'+history.event | translate}}">
          <i *ngIf="history.event === 'show'" class="icon fa fa-eye"></i>
          <i *ngIf="history.event === 'add'" class="icon fa fa-plus-circle"></i>
          <i *ngIf="history.event === 'modify'" class="icon fa fa-pencil"></i>
          <i *ngIf="history.event === 'delete'" class="icon fa fa-trash-o"></i>
          <i *ngIf="history.event === 'action'" class="icon fa fa-hand-pointer-o"></i>
          <i *ngIf="history.event === 'login'" class="icon fa fa-user-circle-o"></i>
          <i *ngIf="history.event === 'changelocation'" class="icon fa fa-refresh"></i>
          <i *ngIf="history.event === 'customization'" class="icon vega-customization"></i>
        </div>
        <div class="text p-2">
          <p class="m-0">
            <b *ngIf="history.id === null || history.verb === null || history.id === '' || history.verb === '' || history.event === 'delete'">{{history.title}}</b>
            <a *ngIf="history.id !== '' && history.verb !== '' && history.id !== null && history.verb !== null && history.event !== 'delete'" (click)="navigate(history)" class="cursor-pointer">
              <i class="fa fa-external-link" aria-hidden="true"></i> {{history.title}}
            </a>
            <span *ngIf="history.subtitle && history.subtitle !== ''">
              <br/>{{history.subtitle}}
            </span> 
          </p>       
          <p class="m-0">
            <i>{{datetimeService.dateFormatFR(history.date)}}</i>
          </p>
        </div>
      </div>
    </div>
    <a class="ms-2 btn btn-danger btn-close-list" aria-label="Close" (click)="closeList();">
      <i class="fa fa-times"></i>
    </a>
  </div>
  <div class="historyHeader">
    <div (click)="getlist(true)" class="historyHeaderbutton text-center cursor-pointer" [class.d-none]="!userHistoryService.showButtonMore">
      <span class="btn btn-sm btn-primary mb-2">{{'userHistory.showAll' | translate}}</span>
    </div>
    <div (click)="clearAllHistory()" class="historyHeaderbutton text-center cursor-pointer" *ngIf="listShowed.length > 0">
      <span class="btn btn-sm btn-danger mb-2">{{'userHistory.clearAll' | translate}}</span>
    </div>
  </div>
</div>

