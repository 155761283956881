import { Injectable } from '@angular/core';
import { UiSyncService } from './ui-sync.service';
import { HttpError } from 'app/models/http-error';
import { MatDialog} from '@angular/material/dialog';
import { MatDialogRef } from '@angular/material/dialog';
import { Util } from 'app/statics/utils';
import { LpModalEvalComponent } from 'app/ui/lp-modal/lp-modal-eval/lp-modal-eval.component';
import { Observable, Subject, lastValueFrom } from 'rxjs';
import { Notification, NotificationType } from "../FormComponents/notification/notification";
import { ComponentType } from '@angular/cdk/portal';

@Injectable({
    providedIn: 'root'
  })
/**
 * Le service ModalService permet d'afficher des messages d'infos/erreurs/succées en modal
 * en utilisant le composant ngx-Toastr.
 *
 * Pour modifier la position de la modal Toastr, utiliser l'option "positionClass" (toast-top-center, toast-bottom-left, etc...)
 * Pour mettre la modal en full largeur, utiliser la poarrayHttpErrorsition "toast-top-full-width".
 */
export class ModalService {

    public closed: boolean = false;
    public arrayHttpError: Array<HttpError> = new Array<HttpError>();
    public arrayHttpWarning: Array<HttpError> = new Array<HttpError>();
    public isMe: boolean = false;
    public currentDialogRef: MatDialogRef<ComponentType<any>>;

    
  private _subject = new Subject<Notification>(); 
  private _idx = 0;
  private notificationTimeout: number = 5000;


    constructor(
        private uiSyncService: UiSyncService,
        public dialog: MatDialog) {}

    getObservable(): Observable<Notification> {
        return this._subject.asObservable();
    }
    /**
     * La fonction error permet d'afficher un message sous fond rouge.
     * @param message obligatoire
     * @param title
     * @param config
     */
    public error(message: string, title?: string): void {
        this.uiSyncService.loader(false);
        this._subject.next(new Notification(this._idx++, NotificationType.error, title, message, this.notificationTimeout));
    }


    /**
     * La fonction success permet d'afficher un message sous fond vert.
     * @param message obligatoire
     * @param title
     * @param config
     */
    public success(message: string, title?: string): void {
        this._subject.next(new Notification(this._idx++, NotificationType.success, title, message, this.notificationTimeout));
    }

    /**
     * la fonction info permet d'afficher un message sous fond bleu.
     * @param message obligatoire
     * @param title
     * @param config
     */
    public info(message: string, title?: string): void {
        this._subject.next(new Notification(this._idx++, NotificationType.info, title, message, this.notificationTimeout));
    }

    /**
     * la fonction warning permet d'afficher un message sous fond jaune.
     * @param message obligatoire
     * @param title
     * @param config
     */
    public warning(message: string, title?: string): void {
        this._subject.next(new Notification(this._idx++, NotificationType.warning, title, message, this.notificationTimeout));
    }

    public getAfterOpened(): Observable<any> {
        return this.currentDialogRef.afterOpened();
    }

    public async modalPromise(componentType: ComponentType<any>, params?: Object, httpError?: HttpError): Promise<boolean> {
        if (httpError !== null && httpError !== undefined) {
            if (httpError.statusCode === 400) {
                this.arrayHttpWarning.push(httpError);
            } else {
                this.arrayHttpError.push(httpError);
            }
        }
        if (params === null || params === undefined) {
            params = new Object();
        }
        if (Util.isNullOrUndefined(params['data'])) {
            params['data'] = {
                result: this.closed
            }
        } else {
            params['data']['result'] = this.closed;
        }
        if ( !this.alreadyExists()) {
            const dialogRef: MatDialogRef<ComponentType<any>> = this.dialog.open(componentType, params);
            this.currentDialogRef = dialogRef;
            let result: boolean = await lastValueFrom(dialogRef.afterClosed());
            if (Util.isNullOrUndefined(result)) {
                result = false;
            }
            if (httpError === null || httpError === undefined || (httpError && httpError.statusCode === 400)) {
                this.removeArrayHttpWarning();
            } else {
                this.removeArrayHttpError();
            }
            return Promise.resolve(result);
        }
    }

    public async evalModalPromise(title: string, message: string, action: boolean, isError?: boolean): Promise<any> {
        try {
            if ( !this.alreadyExists()) {
                const options = {
                    backdropClass: 'alertBackdropClass',
                    id: `modalWithHeader_${message}`,
                    data: {
                        result: this.closed,
                        title: title,
                        message: message,
                        action: Util.isNullOrUndefined(action) ? false : action,
                        isError: isError
                    }
                };
                let dialogRef: MatDialogRef<LpModalEvalComponent>= this.dialog.open(LpModalEvalComponent, options);            
                // Maintenant attendre la fermeture de la modale
                let result: boolean = await lastValueFrom(dialogRef.afterClosed());
                                
                if (Util.isNullOrUndefined(result)) {
                    result = false;
                }
                return Promise.resolve(result);
            }              
        } catch (error) {
            console.log(error);
        }
           
    }

    public removeArrayHttpError(): void {
        this.arrayHttpError = [];
    }

    public removeArrayHttpWarning(): void {
        this.arrayHttpWarning = [];
    }

    public closeAllModal() {
        this.dialog.closeAll();
    }

    private alreadyExists(): boolean {
        let alreadyExist: boolean = false;
        // en commentaire car en l'état on peu pas ouvrir plusieur modale : effet de bord sur les popo erreur multiple
        /*if ( this.dialog && this.dialog.openDialogs && this.dialog.openDialogs.length > 0) {
            alreadyExist = true;
        }*/
        return alreadyExist;
    }
}
